<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveProfile">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('profile') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" sm="4" md="4" class="text-center">
                                    <make-avatar v-model="photo" aspect-ratio="1"/>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                    <v-row>
                                        <v-col class="py-0" cols="12" sm="8">
                                            <ValidationProvider
                                                ref="administrator"
                                                rules="required|min:3|max:255"
                                                v-slot="{ errors, valid }"
                                            >
                                                <TextInput
                                                    id="name"
                                                    :label="$t('administrator_name')"
                                                    v-model="administrator"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="4">
                                            <ValidationProvider
                                                ref="languageUser"
                                                rules="required"
                                                v-slot="{ errors, valid }"
                                            >
                                                <SelectInput
                                                    id="language_interface"
                                                    v-model="languageUser"
                                                    :label="$t('language_interface')"
                                                    :items="listLanguageNames"
                                                    item-text="name"
                                                    item-value="id"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></SelectInput>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider
                                                ref="email"
                                                rules="required|email|min:5|max:50"
                                                v-slot="{ errors, valid }"
                                            >
                                                <TextInput
                                                    id="email"
                                                    :label="$t('Email')"
                                                    v-model="email"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="phone" rules="required|phone" v-slot="{ errors, valid }">
                                                <TextInput
                                                    id="phone"
                                                    :label="$t('phone')"
                                                    v-model="phoneRaw"
                                                    v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="business_phone" rules="phone" v-slot="{ errors, valid }">
                                                <TextInput
                                                    id="business_phone"
                                                    :label="$t('business_phone')"
                                                    v-model="businessPhoneRaw"
                                                    v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="mobile_phone" rules="phone" v-slot="{ errors, valid }">
                                                <TextInput
                                                    id="mobile_phone"
                                                    :label="$t('mobile_phone')"
                                                    v-model="mobilePhoneRaw"
                                                    v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="sex" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="sex"
                                                    :items="sexItems"
                                                    :label="$t('sex')"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                    item-text="name"
                                                    item-value="id"
                                                    v-model="sex"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="6">
                                            <ValidationProvider ref="role" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="sex"
                                                    :items="roleItems"
                                                    item-text="display_name"
                                                    item-value="id"
                                                    :label="$t('role')"
                                                    :disabled="loading || !can(['administrator','hr_manager', 'director'])"
                                                    :readonly="!can(['administrator','hr_manager', 'director'])"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                    v-model="role"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <div class="input_label">{{ $t('birthday_date') }}</div>
                                            <v-menu ref="menu" v-model="menu" :disabled="loading" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <ValidationProvider ref="birthdayProvider" name="birthday" rules="date_format" v-slot="{ errors, valid }">
                                                        <v-text-field v-model="birthday"
                                                                      :label="$t('birthday_date')"
                                                                      readonly
                                                                      v-on="on"
                                                                      @click:clear="birthday = null"
                                                                      hide-details
                                                                      :error-messages="errors"
                                                                      :disabled="loading"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <v-date-picker
                                                    ref="picker" v-model="birthday" :disabled="loading"
                                                    :min="$moment().subtract(80, 'y').toISOString()"
                                                    :max="$moment().toISOString()" :locale="lang"

                                                    @change="setBirthday"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="employee_position" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="employee_position"
                                                    :items="employee_positionItems"
                                                    item-text="name"
                                                    item-value="id"
                                                    :label="$t('employee_position')"
                                                    :disabled="loading || !can(['administrator','hr_manager', 'director'])"
                                                    :readonly="!can(['administrator','hr_manager', 'director'])"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                    v-model="employee_position"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="country" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="employee_position"
                                                    :items="countryItems"
                                                    item-text="name"
                                                    item-value="id"
                                                    :label="$t('country')"
                                                    :disabled="loading || !can(['administrator','hr_manager', 'director'])"
                                                    :readonly="!can(['administrator','hr_manager', 'director'])"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                    v-model="country"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="city" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    id="city"
                                                    :label="$t('city')"
                                                    v-model="city"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="address" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    id="address"
                                                    :label="$t('address')"
                                                    v-model="address"
                                                    :disabled="loading"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                ></TextInput>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="business_process" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="business_process"
                                                    :items="business_processItems"
                                                    item-text="name"
                                                    item-value="id"
                                                    :label="$t('business_process')"
                                                    :disabled="loading || !can(['administrator','hr_manager', 'director'])"
                                                    :readonly="!can(['administrator','hr_manager', 'director'])"
                                                    :error-messages="errors"
                                                    :error="!valid"
                                                    v-model="business_process"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="6">
                                            <ValidationProvider ref="userDepartments" :rules="'oneOf:' + departments.map(item => item.id).join(',')" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="user-department"
                                                    :value="userDepartments"
                                                    :item-text="'name'"
                                                    :item-value="'id'"
                                                    :error="!valid"
                                                    :error-messages="errors"
                                                    :label="$t('admin_profile.departments')"
                                                    :disabled="loading"
                                                    :items="departments"
                                                    multiple
                                                    @input="userDepartments = $event"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="6">
                                            <ValidationProvider ref="timezone" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    id="timezone"
                                                    v-model="timezone"
                                                    :item-text="'name'"
                                                    :item-value="'id'"
                                                    :error="!valid"
                                                    :error-messages="errors"
                                                    :label="$t('timezone')"
                                                    :disabled="loading"
                                                    :items="timeZoneItems"
                                                    multiple
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="about" rules="" v-slot="{ errors, valid }">
                                        <Textarea
                                            id="about"
                                            :label="$t('about')"
                                            v-model="about"
                                            :disabled="loading"
                                            :error-messages="errors"
                                            :error="!valid"
                                            rows="3"
                                        ></Textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-1 pt-3" cols="12">
                                    <span class="title_menu ml-0">{{ $t('administrator_access_password') }}</span>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="password" :rules="form_required + 'min:8'" vid="password" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="password"
                                            :label="$t('password')"
                                            v-model="password"
                                            :disabled="loading"
                                            :error-messages="errors"
                                            :error="!valid"
                                            rows="3"
                                            :type="passwordHidden ? 'password' : 'text'"
                                        >
                                            <template v-slot:append>
                                                <v-icon size="22" color="primary" class="input_icon" @click="passwordHidden = !passwordHidden">
                                                    {{ passwordHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                                                </v-icon>
                                            </template>
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="confirmation_password" rules="required_if:password|confirmed:password" v-slot="{ errors, valid }">

                                        <TextInput
                                            id="password"
                                            :label="$t('repeat_password')"
                                            v-model="password_confirmation"
                                            :disabled="loading"
                                            :error-messages="errors"
                                            :error="!valid"
                                            rows="3"
                                            :type="passwordConfirmHidden ? 'password' : 'text'"
                                        >
                                            <template v-slot:append>
                                                <v-icon size="22" color="primary" class="input_icon" @click="passwordConfirmHidden = !passwordConfirmHidden">
                                                    {{ passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                                                </v-icon>
                                            </template>
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row v-if="can(['administrator','hr_manager', 'director'])">
                                <v-col class="py-0 my-0 d-flex justify-start" cols="12" sm="6">
                                    <v-switch v-model="deleted" v-if="false"
                                              :disabled="loading || !can(['administrator','hr_manager', 'director', 'manager'])"
                                              :readonly="!can(['administrator','hr_manager', 'director', 'manager'])"
                                              :label="deleted ? $t('administrator_deleted') : $t('administrator_remove')"
                                              class="input_switch py-0"
                                              @change="deleted ? active = false : active" color="red" hide-details></v-switch>
                                </v-col>
                                <v-col class="py-0 my-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                    <v-switch v-model="active"
                                              :disabled="loading || deleted || !can(['administrator','hr_manager', 'director', 'manager'])"
                                              :readonly="!can(['administrator','hr_manager', 'director', 'manager'])"
                                              :label="$t('administrator_active')"
                                              class="input_switch"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import MakeAvatar from '../components/MakeAvatar.vue'
import {mapActions, mapGetters} from "vuex";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
import SelectInput from "@/components/Form/SelectInput.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Textarea from "@/components/Form/Textarea.vue";

export default {
    name: 'AdministratorProfile',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
        SelectInput,
        TextInput,
        Textarea
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            departments: [],
            userDepartments: [],
            progress: 0,
            create: false,
            phoneRaw: null,
            mobilePhoneRaw: null,
            businessPhoneRaw: null,
            city: null,
            address: null,
            about: null,
            administrator: null,
            email: null,
            notes: null,
            active: true,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            photo: null,
            role: null,
            roleItems: [],
            sex: null,
            sexItems: [],
            languageUser: null,
            birthday: null,
            menu: false,
            employee_position: null,
            employee_positionItems: [],
            business_process: null,
            business_processItems: [],
            country: 0,
            countryItems: [],
            timezone: 'Asia/Almaty',
            timeZoneItems: [],
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'listLanguageNames', 'lang', 'phoneMask', 'defaultAvatar']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        mobile_phone: function () {
            return this.changePhone(this.mobilePhoneRaw)
        },
        business_phone: function () {
            return this.changePhone(this.businessPhoneRaw)
        },
        form_required: function () {
            if (this.$route.name === 'administrator.profile' || (this.$route.name === 'profile' && this.$auth.check())) {
                return ''
            }
            return 'required|'
        },
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.confirmation_password')})
                ])
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.password')})
                ])
            } else {
                this.$refs.password.reset()
            }
        },
    },
    mounted() {
        this.getSexes()
        this.getBusinessProcess()
        this.getCountries()
        this.getRoles()
        this.getEmployeePositions()
        this.checkCreate()
        this.getDepartments()
        this.getTimeZones()
    },
    methods: {
        ...mapActions(['setUserName', 'setLanguage', 'setUserPosition', 'setUserAvatar', 'setEmployeePosition']),
        checkCreate() {
            if (this.$route.name === 'administrator.create') {
                this.create = true
                this.languageUser = this.lang
            } else if (this.$route.name === 'profile' && this.$auth.check()) {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().id) {
                    this.$route.params.id = this.$auth.user().id;
                    this.getProfile(this.$auth.user().id)
                }
            } else {
                if (this.$route.params.id) {
                    this.getProfile(this.$route.params.id)
                }
            }
        },
        closeDialogAdd() {
            if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'uved',
                })
            }

        },
        setBirthday(date) {
            this.$refs.menu.save(date)
        },
        async getSexes() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/sex`, {
                    params: params,
                })
                .then(res => {
                    this.sexItems = res.body.data
                })
                .catch(err => {
                    this.sexItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                });
        },
        async getCountries() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/country`, {
                    params: params,
                })
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.countryItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_countries'))
                });
        },
        async getRoles() {
            let params = {};
            params.filter = 'getroles'
            params.type_profile_id = 2
            await this.$http
                .get(`admin/role`, {
                    params: params,
                })
                .then(res => {
                    this.roleItems = res.body.data;
                })
                .catch(err => {
                    this.roleItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_roles'))
                })
        },
        async getBusinessProcess() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/business_process`, {
                    params: params,
                })
                .then(res => {
                    this.business_processItems = res.body.data
                    // this.business_processItems.push({'id':0,'name':this.$t('all_deals')})
                })
                .catch(err => {
                    this.business_processItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_business_processes'))
                });
        },
        async getEmployeePositions() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/employee_position`, {
                    params: params,
                })
                .then(res => {
                    this.employee_positionItems = res.body.data;
                })
                .catch(err => {
                    this.employee_positionItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_employee_positions'))
                })
        },
        async getProfile(id) {
            var _this = this;
            this.progress = 0
            this.loading = true
            await this.$http
                .get(`admin/admin/${id}`, {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.administrator = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.businessPhoneRaw = res.body.data.business_phone
                    this.mobilePhoneRaw = res.body.data.mobile_phone
                    this.email = res.body.data.email
                    this.business_process = res.body.data.business_process
                    this.country = res.body.data.country
                    this.timezone = res.body.data.timezone
                    this.photo = res.body.data.photo
                    this.sex = res.body.data.sex
                    this.employee_position = res.body.data.employee_position
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.notes = res.body.data.notes
                    this.role = res.body.data.role
                    this.userDepartments = res.body.data.departments.map(item => item.id)
                    this.birthday = res.body.data.birthday ? this.$moment(res.body.data.birthday).format('YYYY-MM-DD') : null
                    this.about = res.body.data.about
                    this.address = res.body.data.address
                    this.city = res.body.data.city

                    this.languageUser = res.body.data.language ? res.body.data.language : this.lang

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_administrator'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveProfile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }
            if (this.birthday) {
                formData.append('birthday', this.birthday)
            }
            if (this.employee_position) {
                formData.append('employee_position', this.employee_position)
            }
            if (this.administrator) {
                formData.append('administrator', this.administrator)
            }
            if (this.languageUser) {
                formData.append('language', this.languageUser)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.mobile_phone) {
                formData.append('mobile_phone', this.mobile_phone)
            }
            if (this.business_phone) {
                formData.append('business_phone', this.business_phone)
            }
            if (this.about) {
                formData.append('about', this.about)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.sex) {
                formData.append('sex', this.sex)
            }
            if (this.role) {
                formData.append('role', this.role)
            }
            if (this.business_process) {
                formData.append('business_process', this.business_process)
            }
            if (this.country) {
                formData.append('country', this.country)
            }
            if (this.timezone) {
                formData.append('timezone', this.timezone)
            }
            if (this.password) {
                formData.append('password', this.password)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.about) {
                formData.append('about', this.about)
            }
            if (this.address) {
                formData.append('address', this.address)
            }
            if (this.city) {
                formData.append('city', this.city)
            }

            if (this.userDepartments) {
                this.userDepartments.forEach((item) => {
                    formData.append('departments[]', item)
                });
            }

            if (this.$route.params.id && this.$auth.user().id) {
                let id = this.$route.params.id ? this.$route.params.id : this.$auth.user().id
                // Save
                await this.$http
                    .put(`admin/admin/${id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('administrator_has_been_updated'))
                        if ((this.$route.params.id && this.$route.params.id === this.$auth.user().id) || (this.$route.name === 'profile' && this.$auth.check())) {
                            this.$auth.fetch()
                                .then(res => {
                                    this.setUserName(this.$auth.user().name)
                                    this.setUserPosition(this.$auth.user().position)
                                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                                    this.setEmployeePosition(this.$auth.user().employee_position)

                                    this.locale = this.languageUser
                                    document.documentElement.lang = this.languageUser
                                    this.$vuetify.lang.current = this.languageUser
                                    this.$i18n.locale = this.languageUser
                                    this.setLanguage(this.languageUser)
                                    //   this.forceRerender()
                                    this.setCookie('language', this.languageUser, 365)

                                })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('administrator_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/admin', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('administrator_has_been_added'))
                        this.$router.push({
                            name: 'administrator'
                        })
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('administrator_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },
        getDepartments() {
            let params = {
                perPage: '0',
            };

            this.$http
                .get(`admin/department`, {
                    params: params,
                })
                .then(res => {
                    let deps = res.body.data;

                    deps.forEach((item) => {
                        let repeatCount = (item.depth ?? 1) - 1;
                        item.name = ('- '.repeat(repeatCount)) + item.name;
                        return item;
                    })

                    deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                    deps = buildListArrayFromTreeArray(deps);

                    this.departments = deps;
                })
                .catch(err => {
                    this.departments = [];
                    this.$toastr.error(this.$t('failed_to_get_list_departments'));
                });
        },
        async getTimeZones() {
            var _this = this
            await this.$http.get(
                `admin/timezone`,
            )
                .then(res => {
                    this.timeZoneItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_time_zones'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
    }
}
</script>
