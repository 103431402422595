<script>
export default {
    name: "Textarea",

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        persistentHint: Boolean
    }
}
</script>

<template>
    <div>
        <label class="input_label" v-if="!hideLabel" :for="id">{{ label }}</label>
        <v-textarea
            class="input_text"
            outlined
            solo
            flat
            dense
            clearable
            @input="$emit('input', $event)"
            :value="value"
            :label="label"
            :id="id"
            :hint="hint"
            persistent-hint
            hide-details
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template v-for="(slotContent, slotName) in $slots" :slot="slotName">
                <slot :name="slotName"></slot>
            </template>
        </v-textarea>
    </div>
</template>
